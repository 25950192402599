@charset "utf-8";

@font-face{
    src:url("./font/AAK.otf");
    font-family: "AAK-primary";
  }
:root{
    --lightblue:#D4E4EC;
    --orange:#F5A300;
    --darkblue:#042B4E;
    --darkgreen:#006837;
    --pink: #F2A6B8;
    --darkpink: #BB114F;
    --aak-font: "AAK-primary", "Poppins", "Montserrat", "Roboto", sans-serif;
}
body{
    background: var(--lightblue);
  }
h1{
    font-family: var(--aak-font);
    color:var(--orange);
    text-align: center;
    font-size: 64px ;
    line-height: 58px;
}
.glass{
    background: rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    height: min-content;
  }
.btn-primary{
    background: var(--orange);
    border: 0;
    transition: all .3s;
}
.btn-primary.disabled{
    background: transparent;
    border: 1px solid var(--darkblue);
    color: var(--darkblue);
    transition: all .3s;
}
.btn-primary:hover, .btn-primary:active{
    background: var(--orange);
    border: 0;
    opacity: .7 !important;
    transition: all .3s;
}
a{
text-decoration: none;
color: unset;
}
.grass{
    bottom:-5px !important;
    z-index:-1;
}